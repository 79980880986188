import React, { useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import { useStore } from 'store/StateProvider';
import { ROUTES } from 'common/constants/routing';
import { MobileImages } from 'common/constants/image-resource';
import { getLocalStorage, setLocalStorage, isUserLoggedIn } from 'common/helpers/localStorage';
import { useGetBalance } from 'services/walletService';
import GoBack from 'component/mobile/common/goback/GoBack';
import IconClose2 from 'component/mobile/common/icon/IconClose2';
import { MemberBalanceContext } from 'component/shared/provider/MemberBalanceProvider';
import Profile from './profile/Profile';
import MemberBalance from './profile/MemberBalance';
import styles from './Topbar.module.css';
import Sidebar from '../Topbar/sidebar/Sidebar';

const Topbar = ({
  showCloseButton = false,
  handleCloseGame,
  hasLeftBack = false,
  isWelcomeSnackBar,
  isAffiliateSnackBar,
}) => {
  const [store] = useStore();
  const navigate = useNavigate();
  const { user } = store;
  const intl = useIntl();
  const { setMemberBalance } = useContext(MemberBalanceContext);
  const [{ data }, refetch] = useGetBalance(true);
  const isLoggedIn = isUserLoggedIn();
  const closeHandler = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (data) {
      setMemberBalance(data.truncatedBalance);
    }
  }, [data, setMemberBalance]);

  useEffect(() => {
    const handleClick = () => {
      const callRefetch = getLocalStorage('refetch');
      if (callRefetch && isLoggedIn) {
        refetch();
        setLocalStorage('refetch', false);
      }
    };
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [refetch, isLoggedIn]);

  return (
    <>
      <div
        className={`${styles.header} ${
          (isWelcomeSnackBar && !isLoggedIn && !handleCloseGame) ||
          (isAffiliateSnackBar && isLoggedIn && !handleCloseGame)
            ? styles.hasTop
            : styles.noneTop
        } header`}
        id="topbar-header"
      >
        <div className={styles.leftHeader}>
          {!handleCloseGame && !showCloseButton && !hasLeftBack && <Sidebar />}
          {handleCloseGame || hasLeftBack ? (
            <>
              <GoBack
                backEvent={hasLeftBack ? null : (e) => handleCloseGame(e)}
                arrowClass={styles.backArrow}
              />
              <div>
                <img
                  onClick={(e) => {
                    if (!hasLeftBack) {
                      handleCloseGame(e);
                    }
                    navigate(ROUTES.HOME);
                  }}
                  src={MobileImages.logoPrimary}
                  width={90}
                  height={30}
                  alt="logo"
                  className={styles.logoBack}
                />
              </div>
            </>
          ) : (
            <div>
              <Link to={ROUTES.HOME}>
                <img
                  src={MobileImages.logoPrimary}
                  width={90}
                  height={30}
                  alt="logo"
                  className={styles.logo}
                />
              </Link>
            </div>
          )}
        </div>
        {showCloseButton ? (
          <div onClick={closeHandler}>
            <IconClose2 />
          </div>
        ) : isLoggedIn ? (
          <div className={styles.accountDetails}>
            {user && <MemberBalance handleCloseGame={handleCloseGame ? handleCloseGame : null} />}
            <Profile
              nickName={user?.nickName}
              loginName={user?.loginName}
              vipLevelName={user?.vipLevelName}
              vipLevel={user?.vipLevel}
              vipLevelProgress={user?.vipLevelProgress}
              handleCloseGame={handleCloseGame ? handleCloseGame : null}
            />
          </div>
        ) : (
          <div className={styles.beforeLogin}>
            <div
              onClick={(e) => {
                if (handleCloseGame) {
                  handleCloseGame(e);
                }
                navigate(ROUTES.ACCOUNT.LOGIN);
              }}
            >
              <div className={styles.login}>{intl.formatMessage({ id: 'topbar.signIn' })}</div>
            </div>
            <div
              onClick={(e) => {
                if (handleCloseGame) {
                  handleCloseGame(e);
                }
                navigate(ROUTES.ACCOUNT.REGISTER);
              }}
            >
              <div className={styles.registerBtn}>
                <div className={styles.btnText}>{intl.formatMessage({ id: 'topbar.signUp' })}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Topbar;
